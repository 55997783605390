export default class GlobalErrorMessage extends Component {
  componentDidMount() {
    $('.modal-backdrop').addClass('with-global-error')

    // Ensure the error message is in view when it appears
    const errorMessageElement = document.querySelector('.global-error-message');
    if (errorMessageElement) {
      errorMessageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  componentWillUnmount() {
    $('.modal-backdrop').removeClass('with-global-error')
  }

  render() {
    let { error } = App.state
    if (!error) return null
    return (
      <div className="global-error-message">
        <div className="global-error-message-text alert alert-danger">{error.message}</div>
        <div className="global-error-message-actions">
          <button className='btn btn-sm btn-primary' onClick={this.okay}>Okay</button>
        </div>
      </div>
    )
  }

  okay = () => {
    App.state.setError(null)
  }
}
