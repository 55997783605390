import { GradientedScroller, connected, withErrorBoundary, NoResults } from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import ParkCard from 'ui/parks/ParkCard'
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

calendarGlobal = []

MobilePlacesCalenderView = () -> 
  [calendar, setCalendar] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  [openAvailable, setOpenAvailable] = useState([])
  [selectedDate, setSelectedDate] = useState(new Date())

  location = useLocation()
  queryParams = new URLSearchParams(location.search)

  parseDate = (dateTime) =>
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar, token) =>
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate),
      token: token
    }

  loadData = (token, calendar = null) ->
    calendar = calendar ? calendar : { startDate: new Date(), endDate: new Date() }
    Backend.get '/places/mobile_available', 
      dateParams(calendar, token)
    .then (response) ->
      setOpenAvailable(response.places)



  updateDateRange = (range) =>
    setSelectedDate(range.selection.startDate)
    setCalendar([range.selection])

  useEffect(() => 
    calendarGlobal = calendar
    token = queryParams.get('token')
    loadData(token, calendarGlobal[0])
    return # Keep this to prevent useEffect cleanup warnings
  , [calendar])

  <TopLevelPage className="PlacesCalender" modelId='moile' base="/mobile-places-calendar">
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col text-center">
        <DateRangePicker
          onChange={updateDateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[{ startDate: selectedDate, endDate: selectedDate, key: 'selection' }]}
          direction="horizontal"
          inputRanges={[]}
          editableDateInputs={false}
          showDateDisplay={false} 
        />
      </div>
    </div>
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3">Community Reservations</h1>
        </div>
      </div>

      <div className="row">
        { if (openAvailable.length == 0)
            <NoResults>All reservable amenities are currently reserved for today.</NoResults>
          else
            for model in openAvailable
              <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                <ParkCard park={model} base='/mobile-places-calendar' selectedDate={format(selectedDate, "yyyy-MM-dd")}/>
              </div>
        }
      </div>
    </section>
  </TopLevelPage>

export default withErrorBoundary connected MobilePlacesCalenderView
